<template>
  <GDialog v-model="dialogState" max-width="500" persistent>
    <div class="wrapper">
      <div class="content">
        <div class="title">Zgłoszenia niedostępne</div>
        <p>Zgłoszenia są jeszcze zamknięte.<br>Wróć później.</p>
      </div>
    </div>
  </GDialog>
</template>

<script>
export default {
  name: "WrongDateDialog",
  data() {
    return {
      dialogState: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  color: #000;
}

.content {
  padding: 20px;
}

.title {
  font-size: 120%;
  text-transform: none;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.loader {
  width: 100%;
  text-align: center;
}

</style>
