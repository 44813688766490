<template>
  <div id="printEl" v-if="showRegistration">
    <RegistrationHeader/>
<!--    <RegistrationRounds-->
<!--      ref="RegistrationRounds"-->
<!--      object-name="roundsData"-->
<!--      @update-data="updateData"-->
<!--      @update-is-all="updateIsAll"-->
<!--    />-->
    <RegistrationCompetitors
      ref="RegistrationCompetitors"
      object-name="competitorsData"
      @update-data="updateData"
      @update-is-all="updateIsAll"
    />
    <div id="ice-title" class="annotation comp-annotation">* wypełnić w przypadku posiadania licencji</div>
    <RegistrationICE
      ref="RegistrationICE"
      object-name="iceData"
      @update-data="updateData"
      @update-is-all="updateIsAll"
    />
    <RegistrationPolicy
      ref="RegistrationPolicy"
      object-name="policyData"
      @update-data="updateData"
      @update-is-all="updateIsAll"
    />
    <div style="page-break-before: always; width: 100%; height: 3px"></div>
    <RegistrationCar
      ref="RegistrationCar"
      object-name="carData"
      @update-data="updateData"
      @update-is-all="updateIsAll"
    />
    <RegistrationEntryFee
      ref="RegistrationEntryFee"
      object-name="entryFeeData"
      @update-data="updateData"
      @term-not-exist="setTermNotExist"
      @is-too-early="setIsTooEarly"
      @update-is-all="updateIsAll"
      :entry-fee-amount="entryFeeAmount"
      :competitors-data="competitorsData"
      style="margin-bottom: 7px"
    />
    <RegistrationStatements
      ref="RegistrationStatements"
      object-name="statementsData"
      @update-data="updateData"
      @update-is-all="updateIsAll"
    />
    <RegistrationSignatures ref="RegistrationSignatures"/>
    <RegistrationInvoice
      ref="RegistrationInvoice"
      object-name="invoiceData"
      @update-data="updateData"
      @update-is-all="updateIsAll"
    />
  </div>
  <RegistrationSubmit
    ref="RegistrationSubmit"
    object-name="submitData"
    :competitors-data="competitorsData"
    @update-data="updateData"
    @update-is-all="updateIsAll"
    class="last"
    v-if="showRegistration"
  />
  <div id="button-div" v-show="showRegistration">
    <form @submit.prevent="onSubmit">
    <div id="recaptcha" class="g-recaptcha"
         data-sitekey="6Lcg8aAeAAAAAP31-W8j8BEWLhXD8BoCb_JXULTJ"
         data-callback="sendRegistration"
         data-size="invisible"></div>
    <button type="submit" id="submit">{{ submitButtonText }}</button>
    </form>
  </div>
  <RegistrationDialog
    :loading-dialog="dialog.loading"
    :open-dialog="dialog.open"
    :error-code="dialog.errorCode"
    v-if="showRegistration"
  />
  <ChangeDeviceDialog v-if="isIOS"/>
  <NoTermExistDialog v-if="termNotExist"/>
  <TooEarlyDialog v-if="isTooEarly"/>
  <WrongDateDialog v-if="isWrongDate"/>

</template>

<script>
import RegistrationHeader from "@/components/RegistrationHeader";
import RegistrationCompetitors from "@/components/RegistrationCompetitors";
import RegistrationICE from "@/components/RegistrationICE";
import RegistrationCar from "@/components/RegistrationCar";
import RegistrationEntryFee from "@/components/RegistrationEntryFee";
import RegistrationStatements from "@/components/RegistrationStatements";
import RegistrationSignatures from "@/components/RegistrationSignatures";
import axios from 'axios'
import html2pdf from "html2pdf.js/src";
import RegistrationSubmit from "@/components/RegistrationSubmit";
import RegistrationDialog from "@/components/RegistrationDialog";
import ChangeDeviceDialog from "@/components/ChangeDeviceDialog";
import NoTermExistDialog from "@/components/NoTermExistDialog";
// import RegistrationRounds from "@/components/RegistrationRounds.vue";
import RegistrationPolicy from "@/components/RegistrationPolicy.vue";
import RegistrationInvoice from "@/components/RegistrationInvoice.vue";
import TooEarlyDialog from "@/components/TooEarlyDialog.vue";
import WrongDateDialog from "@/components/WrongDateDialog.vue"

// const config = require("@/const/config")
/* global grecaptcha */

export default {
  name: 'RegistrationForm',
  components: {
    TooEarlyDialog,
    RegistrationPolicy,
    // RegistrationRounds,
    RegistrationInvoice,
    RegistrationDialog,
    ChangeDeviceDialog,
    RegistrationSubmit,
    RegistrationHeader,
    RegistrationCompetitors,
    RegistrationICE,
    RegistrationCar,
    RegistrationEntryFee,
    RegistrationStatements,
    RegistrationSignatures,
    NoTermExistDialog,
    WrongDateDialog,
  },
  data() {
    return {
      // roundsData: {},
      competitorsData: {},
      iceData: {},
      policyData: {},
      carData: {},
      entryFeeData: {},
      statementsData: {},
      invoiceData: {},
      submitData: {},
      isAll: {
        // roundsData: false,
        competitorsData: false,
        iceData: false,
        policyData: false,
        carData: false,
        entryFeeData: false,
        statementsData: false,
        submitData: false,
      },
      loadingRecaptcha: false,
      dialog: {
        open: false,
        loading: false,
        errorCode: "",
      },
      disableSubmitButton: false,
      submitButtonText: "Wyślij zgłoszenie",
      termNotExist: false,
      isTooEarly: false,
      debugOpenUsed: false,
      isLoading: true,
      isWrongDate: false,

      isTesting: false, //debug
    }
  },
  mounted() {
    document.getElementById('submit').onclick = this.onRecaptchaClick;
    window.autoFill = this.autoFill;
    window.debugOpen = this.debugOpen;
    const sendRegistration = this.sendRegistration;
    this.isLoading = false
    // setTimeout(() => this.isLoading = false, 1500)
    this.loadGrecaptcha().then(() => {
      grecaptcha.render(document.getElementById('recaptcha'), {
        sitekey: "6Lcg8aAeAAAAAP31-W8j8BEWLhXD8BoCb_JXULTJ",
        size: "invisible",
        callback: (token) => {
          sendRegistration(token);
          grecaptcha.reset(this.widgetId);
        }
      });
    });
    this.checkSystemDate()
  },
  methods: {
    async loadGrecaptcha() {
      if (typeof grecaptcha !== "undefined" && typeof grecaptcha.render === "function") {
        return Promise.resolve(grecaptcha);
      }

      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
        script.onload = () => {
          const checkGrecaptcha = setInterval(() => {
            if (typeof grecaptcha !== "undefined" && typeof grecaptcha.render === "function") {
              clearInterval(checkGrecaptcha);
              resolve(grecaptcha);
            }
          }, 100); // check every 100ms
        };
        document.head.appendChild(script);
      });
    },
    onSubmit: function(){},
    onRecaptchaClick: function() {
      grecaptcha.execute();
    },
    updateData: function (value) {
      this[value.objectName] = value.data
    },
    updateIsAll: function (value) {
      this.isAll[value.objectName] = value.data
    },
    sendRegistration: function (recaptcha) {
      const opt = {
        margin: [10, 20, 10, 20],
        filename: name + '.pdf',
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {dpi: 300, scale: 4, letterRendering: true},
        jsPDF: {unit: 'pt', format: 'a4', orientation: 'portrait'},
        pagebreak: {mode: ['css']}
      };
      const element = document.getElementById('printEl');
      this.stylesBeforePrint(element);
      document.getElementById('printEl').setAttribute("content", "width=1000");
      if (this.isTesting) {
        html2pdf().set(opt).from(element).toPdf().get('pdf').save();
        this.stylesAfterPrint(element);
        return
      }
      let isAll = true;
      for (const value of Object.values(this.isAll))
        if (!value)
          isAll = false;
      if (isAll) {
        this.dialog.loading = true
        this.dialog.open = true
        this.dialog.errorCode = null
        this.disableSubmitButton = true
        html2pdf().set(opt).from(element).outputPdf('arraybuffer').then((result) => {
          const formData = new FormData()
          formData.append('pdf', new Blob([result], {type: "application/pdf"}));
          formData.append('recaptcha', recaptcha)
          // formData.append('roundsData', JSON.stringify(this.roundsData))
          formData.append('competitorsData', JSON.stringify(this.competitorsData))
          formData.append('iceData', JSON.stringify(this.iceData))
          formData.append('policyData', JSON.stringify(this.policyData))
          formData.append('carData', JSON.stringify(this.carData))
          formData.append('entryFeeData', JSON.stringify(this.entryFeeData))
          formData.append('statementsData', JSON.stringify(this.statementsData))
          formData.append('invoiceData', JSON.stringify(this.invoiceData))
          formData.append('entryFeeAmount', this.entryFeeAmount)
          formData.append('targetEmail', this.submitData.emailInput)
          formData.append('confirmation', this.submitData.confirmation)
          formData.append('userAgent', navigator.userAgent)
          axios({
            method: 'post',
            url: 'mailer/Mailer.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
          }).then(response => {
            this.submitButtonText = "Wysłano"
            console.log(response)
          }).catch(err => {
            this.disableSubmitButton = false
            if (/^\d{5}$/.test(err.response.data)) {
              this.dialog.errorCode = err.response.data
            } else {
              this.dialog.errorCode = "Nieznany"
              console.log(err)
            }
          }).finally(() => {
            this.dialog.loading = false
            this.dialog.open = false
            document.getElementById('printEl').setAttribute("content", element);

          })
        });
      } else {
        // this.$refs.RegistrationRounds.focusRequired();
        this.$refs.RegistrationCar.focusRequired();
        this.$refs.RegistrationCompetitors.focusRequired();
        this.$refs.RegistrationEntryFee.focusRequired();
        this.$refs.RegistrationICE.focusRequired();
        this.$refs.RegistrationStatements.focusRequired();
        this.$refs.RegistrationInvoice.focusRequired();
        this.$refs.RegistrationPolicy.focusRequired();
        this.$refs.RegistrationSubmit.focusRequired();
      }
      this.stylesAfterPrint(element);
    },
    autoFill: function (jsonData) {
      try {
        const obj = JSON.parse(jsonData);
        console.log(obj)
        this.$refs.RegistrationCar.autoFill(obj.carData);
        // this.$refs.RegistrationRounds.autoFill(obj.roundsData)
        this.$refs.RegistrationCompetitors.autoFill(obj.competitorsData);
        this.$refs.RegistrationEntryFee.autoFill(obj.entryFeeData)
        this.$refs.RegistrationICE.autoFill(obj.iceData);
        this.$refs.RegistrationPolicy.autoFill(obj.policyData);
        this.$refs.RegistrationStatements.autoFill(obj.statementsData);
        this.$refs.RegistrationInvoice.autoFill(obj.invoiceData);
        this.$refs.RegistrationSubmit.autoFill(obj.targetEmail);
      } catch (e) {
        console.log(e)
        alert("Coś jest nie tak :P")
      }
    },
    debugOpen: function () {
      this.isTooEarly = false;
      this.termNotExist = false;
      this.debugOpenUsed = true;
    },
    setTermNotExist: function() {
      if(!this.debugOpenUsed)
        this.termNotExist = true;
    },
    setIsTooEarly: function() {
      if(!this.debugOpenUsed)
        this.isTooEarly = true;
    },
    stylesBeforePrint: function(element){
      element.style.fontSize="85%";
    },
    stylesAfterPrint: function(element){
      requestAnimationFrame(() => {
        element.style.transform = 'none';
      })
    },
    checkSystemDate: function() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      fetch(`https://timeapi.io/api/Time/current/zone?timeZone=${timezone}`)
        .then(response => response.json())
        .then(data => {
          const serverDate = new Date(data.dateTime);
          const systemDate = new Date();
          const timeDifference = Math.abs(systemDate.getTime() - serverDate.getTime());
          const allowableDifference = 5 * 60 * 1000;
          console.log(serverDate.toUTCString(), systemDate.toUTCString(), timeDifference, allowableDifference)
          if (timeDifference > allowableDifference) {
            this.isWrongDate = true;
          }
        })
        .catch(() => {
          this.isWrongDate = true;
        });
    }
  },
  computed: {
    entryFeeAmount: function () {
      const noAds = this.entryFeeData.advertisement === "Nie";
      const type = this.entryFeeData.feeType1 === 'Tak' ? 'amk' : this.entryFeeData.feeType2 === 'Tak' ? 'pzm' : 'none';
      // const rounds = (this.roundsData.round1 === 'Tak' ? 1 : 0) + (this.roundsData.round2 === 'Tak' ? 1 : 0)
      const rounds = 1;
      if (this.entryFeeData.term && this.entryFeeData.feeType1 && this.entryFeeData.feeType2) {
        const entryFeeByType = this.entryFeeData.term.entryFeeByType.find(o => o.rounds === rounds && o.type === type && o.noAds === noAds)
        if (entryFeeByType)
          return `${entryFeeByType.entryFee} PLN`
      }
      return '-'
    },
    isIOS: function () {
      return ([
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document))
    },
    showRegistration: function () {
      return !this.isLoading && !this.isIOS && !this.termNotExist && !this.isTooEarly && !this.isWrongDate
    }
  }
}
</script>

<style lang="scss">
$baseColor: #99b95e;

body {
  background-color: #fffffc;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  width: 75%;
  margin: 1.5em auto;
  background-color: $baseColor;
}


.center {
  text-align: center;
}

.title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 110%
}

.header-title {
  font-weight: bold;
  font-size: 140%;
}


.header-sub-title {
  //font-weight: bold;
  font-size: 100%
}

.header-title2, .header-sub-title {
  display: table;

  span {
    display: table-cell;
    vertical-align: middle;
  }
}

.annotation {
  grid-gap: 2px;
  font-size: 80%;
  margin: 0 -2px -2px;
  background-color: white;
}

.comp-annotation {
  text-align: left;
  color: black;
  margin-top: -10px;
  margin-bottom: 10px;
  padding: 4px 4px 10px;
  background-color: white;

}

div.component {
  margin-bottom: 10px;
  background-color: black;
  display: grid;
  grid-gap: 2px;
  padding: 2px;
  text-align: left;
  box-sizing: border-box;
  page-break-inside: avoid;

  div {
    padding: 0.2em;
    background-color: white;
    color: black;
    grid-auto-rows: minmax(min-content, max-content);
    min-height: 0;
  }

  input[type=text], input[type=date], input[type=email], input[type=tel], select {
    background-color: #e7e7e7;
    height: 100%;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    text-align: center;
    border: none;
  }

  .input-div {
    padding: 0;
  }

  input[type=radio].better-radio {
    display: none;
  }

  input[type=radio].better-radio + label {
    border: 1px solid black;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  label {
    height: 100%;
  }

  input[type=radio].checked + label {
    background-color: $baseColor;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  }

  input[type=radio].better-radio:after + label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "\274c"; /* use the hex value here... */
    font-size: 50px;
    color: #FFF;
    line-height: 100px;
    text-align: center;
  }

  input[type=radio].checked + label span {
    display: block;
  }

  label span {
    display: none;
    line-height: 1;
  }
}

div.last {
  margin-bottom: 0;
  background-color: black;
}

#button-div {
  padding-top: 20px;
  width: 100%;
  background-color: white !important;

  button, input[type=file] {
    font-size: 120%;
  }
}

.is-required {
  -webkit-box-shadow: inset 0px 0px 4px 1px rgba(255, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 4px 1px rgba(255, 0, 0, 1);
  box-shadow: inset 0px 0px 4px 1px rgba(255, 0, 0, 1);
}

input[type="checkbox"].better-checkbox {
  opacity: 0;
  position: absolute;

  ~ label.is-required {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &:before {
      -webkit-box-shadow: inset 0px 0px 4px 1px rgba(255, 0, 0, 1);
      -moz-box-shadow: inset 0px 0px 4px 1px rgba(255, 0, 0, 1);
      box-shadow: inset 0px 0px 4px 1px rgba(255, 0, 0, 1);
    }
  }

  ~ label:before {
    content: '';
    display: inline-block;
    cursor: pointer;
    width: 12px;
    height: 12px;
    //background: #999;
    border: 1px solid #999;
    border-radius: 2px;
    transition: .2s;
  }

  &:checked ~ label:before {
    background-color: $baseColor;
    background-image: url('~@/assets/check_mark.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px 8px;
  }
}

@media (max-width: 961px) {
  #app {
    width: 100%;
    margin: 0 auto;
    font-size: 2.5vw;
  }
}

@media (min-width: 1500px) {
  .header-title {
    font-weight: bold;
    font-size: 140%;
  }

  .header-sub-title {
    //font-weight: bold;
    font-size: 120%
  }
}
</style>
