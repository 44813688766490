<template>
  <div id="statements" class="component">
    <div id="title" class="title">Oświadczenie</div>
    <div id="statement-input"><input type="checkbox" v-model="data.statement" class="better-checkbox"
                                     id="statement-input-checkbox"><label for="statement-input-checkbox"
                                                                          name="statement"></label></div>
    <div id="statement">
      <p>Przez sam fakt podpisu na formularzu zgłoszeniowym uczestnik jak i również wszyscy członkowie ekipy przyjmują
        tym samym bez zastrzeżeń przepisy Regulaminu Amatorskich Imprez Sportowych oraz Regulaminu Uzupełniającego
        jak i wszystkich załączników i uzupełnień do imprez i uznają jako jedyne władze te, które zostały ustalone przez
        OKSS PZM w Rzeszowie.</p>
      <p>Jednocześnie uczestnicy oświadczają, iż biorą udział w imprezie na własne ryzyko, przejmują na siebie pełną
        odpowiedzialność za powstałe z ich winy szkody, a tym samym zrzekają się wszelkich roszczeń w stosunku do
        Organizatora.</p>
      <p>Kierowca oświadcza,</p>
      <p> - że samochód zgłoszony do udziału w imprezie jest sprawny, posiada aktualne badanie techniczne, ubezpieczenie
        NNW i OC, oraz że wszystkie powyższe dane są zgodne ze stanem faktycznym, pod rygorem wykluczenia</p>
      <p> - że nie posiada licencji kierowcy w rozumieniu regulaminów sportowych PZM </p>
      <p>Administratorem danych osobowych jest Automobilklub Małopolski Krosno i mogą być one wykorzystywane:</p>
      <p> - w celu klasyfikacji rundy oraz klasyfikacji sezonu przez PZM;</p>
      <p> - wydania oraz wznawiania na wniosek zainteresowanego dokumentów uprawniających do udziału w danej dyscyplinie
        sportu motorowego;</p>
      <p> - w celu oceny zdolności zawodników do uprawiania danej dyscypliny sportu motorowego;</p>
      <p> - w celach podlegania ew. odpowiedzialności dyscyplinarnej;</p>
      <p> - w celach przyznawania tytułów mistrzowskich, gdy ma zastosowanie;</p>
      <p>Podstawą prawną przetwarzania Pani/Pana danych osobowych jest art. 6 ust. 1 lit. a RODO tj. zgoda osoby której
        dane dotyczą.</p>
      <p>Automobilklub Małopolski będzie przechowywać Pani/Pana dane osobowe – przez 2 lata.</p>
      <p>W związku z przetwarzaniem przez Automobilklub Małopolski Krosno Pani/Pana danych osobowych, przysługuje
        Pani/Panu prawo:</p>
      <p> - żądania od Automobilklubu Małopolskiego dostępu do Pani/Pana danych osobowych, ich sprostowania oraz
        otrzymania kopii tych danych, jak również otrzymania pełnej informacji o ich przetwarzaniu;</p>
      <p> - żądania od Automobilklubu Małopolskiego Krosno usunięcia Pani/Pana danych osobowych lub ograniczenia ich
        przetwarzania wyłącznie do ich przechowywania; Automobilklub Małopolski Krosno poinformuje Panią/Pana o
        uwzględnieniu tego żądania lub poda przyczyny, dla których żądanie to nie może być zrealizowane;</p>
      <p> - cofnięcia zgody w przypadku, gdy Automobilklub Małopolski Krosno będzie przetwarzał Pani/Pana dane osobowe w
        oparciu o zgodę, w każdym czasie poprzez złożenie pisemnego wniosku o zaprzestanie przetwarzania Pani/Pana
        danych osobowych. Skutkiem wycofania zgody jest brak możliwości dalszego procedowania Pani/Pana sprawy przez
        Automobilklub Małopolski Krosno.</p>
      <p> - wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, na przetwarzanie Pani/Pana danych osobowych
        przez Automobilklub Małopolski Krosno, jeżeli uzna Pani/Pan, że przetwarzanie danych osobowych narusza przepisy
        RODO.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationStatements",
  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      data: {
        statement: false
      },
      required: {
        statement: true
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function (data) {
      this.data = {...data}
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if (this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#statements {
  grid-template-columns: 1fr 8fr;
  grid-template-areas:
      "title title"
      "statement-input statement";

  div:not(#title) {
    font-size: 80%;

    p {
      margin: 0.1em;
    }
  }

  #statement-input {
    text-align: center;
    vertical-align: middle;
    padding-top: 1em;

    input[type=checkbox] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(1.5); /* IE */
      -moz-transform: scale(1.5); /* FF */
      -webkit-transform: scale(1.5); /* Safari and Chrome */
      -o-transform: scale(1.5); /* Opera */
      transform: scale(1.5);
    }
  }

  #title {
    grid-area: title;
  }

  #statement {
    grid-area: statement;
  }

  #statement-input {
    grid-area: statement-input;
  }
}
</style>
