<template>
  <div id="header" class="component">
    <div id="logo" class="center"><img :src="getLogoImg()" style="width: 80%"/></div>
    <div id="name1" class="center header-title"><span>{{ config.title }}</span></div>
    <div id="date" class="center header-sub-title"><span>{{ config.subTitle }}</span></div>
    <div id="amk" class="center"><img src="@/assets/automalop.jpg"/></div>
    <div id="pzm" class="center"><img src="@/assets/PZM.jpg"/></div>
    <div id="kolo" class="center"><img src="@/assets/ziemisanockiej.png"/></div>
    <div id="no-title" class="center">Nr załogi</div>
    <div id="no"></div>
  </div>
</template>

<script>
const config = require("@/const/config")
export default {
  name: "RegistrationHeader",
  props: {
  },
  data() {
    return {
      config: config,
    }
  },
  methods: {
    getLogoImg() {
      return require('../assets/'+config.logo)
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  background-color: white;
  border: 2px solid white;
  row-gap: 0;
  grid-auto-rows: 1fr;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
      "logo kolo amk pzm no-title"
      "logo kolo amk pzm no"
      "logo kolo amk pzm no"
      "logo kolo amk pzm no"
      "name1 name1 name1 name1 name1"
      "date date date date date";
  img {
    width: 100%;
  }
  #logo {
    grid-area: logo;
  }
  #logo, #amk, #pzm, #kolo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #name1 {
    grid-area: name1;
  }
  #name2 {
    grid-area: name2;
  }

  #date {
    grid-area: date;
  }
  #kolo {
    grid-area: kolo;
  }
  #pzm {
    grid-area: pzm;
  }

  #amk {
    grid-area: amk;
  }

  #no-title {
    margin: 2px 2px 0 2px;
    border: 2px solid black;
    border-bottom: 0;
    grid-area: no-title;
  }

  #no {
    margin: 0 2px 2px 2px;
    border: 2px solid black;
    grid-area: no;
  }
}
</style>
